/* eslint-disable no-console */
import axios from "axios";
import { getDocumentURL, generateEnvelope } from "./eSignatureMethods";

const getRecipientsRoute = window.REACT_APP_RECIPIENTS_ROUTE;

export const getRecipients = (accountId, envelopeId) => {
  return axios
    .post(getRecipientsRoute, {
      data: {
        accountId,
        envelopeId
      }
    })
    .then(res => {
      return res.data.map(recipient => {
        return {
          recipientId: recipient.recipientId,
          status: recipient.status
        };
      });
    });
};

export const checkSigners = async (
  counter,
  tempEnvelopeState,
  _tempPageLoadState,
  tempRolesState,
  localization
) => {
  const recipients = await getRecipients(
    tempRolesState.accountId,
    tempEnvelopeState.envelopeId
  );

  const recipient = recipients.find(
    item => item.recipientId === counter.toString()
  );

  // a message is sent if the current signer has not signed
  if (recipient.status === "sent" || recipient.status === "delivered") {
    return localization.NextError;
  }
  return "";
};

export function createEnvelope(
  signers,
  tempEnvelopeState,
  tempPageLoadState,
  tempRolesState,
  localization,
  setEnvelopeCreated,
  setShowForm,
  setError,
  setLoading,
  boundStoreURL,
  boundInsertCustomer
) {
  const formNumber = tempPageLoadState.routeState.formnumber;
  const isAdvisorPrefill = formNumber[formNumber.length - 1] === "P";
  let firstName = tempPageLoadState.repsourceFirstname;
  let lastName = tempPageLoadState.repsourceLastname;
  if (
    signers.every(
      item => item.roleName !== "Advisor" && item.roleName !== "Conseiller"
    )
  ) {
    const secondSignerIndex = 1;
    const firstNameIndex = 1;
    const lastNameIndex = 2;
    const firstSignerIndex = 0;
    if (!isAdvisorPrefill && signers.length > 1) {
      firstName =
        signers[secondSignerIndex].tabs.textTabs[firstNameIndex].value;
      lastName = signers[secondSignerIndex].tabs.textTabs[lastNameIndex].value;
    } else {
      firstName = signers[firstSignerIndex].tabs.textTabs[firstNameIndex].value;
      lastName = signers[firstSignerIndex].tabs.textTabs[lastNameIndex].value;
    }
  }
  generateEnvelope(
    signers,
    tempRolesState.templateID,
    tempRolesState.accountId,
    tempPageLoadState.RepsourceID,
    tempEnvelopeState.policyNumber,
    firstName,
    lastName,
    tempPageLoadState.repsourceFirstname,
    tempPageLoadState.repsourceLastname,
    tempPageLoadState.repsourceEmail,
    tempPageLoadState.repsourceRole,
    tempPageLoadState.routeState.formnumber,
    tempRolesState.formInfo,
    tempPageLoadState.routeState.token,
    tempPageLoadState.ciamSession
  )
    .then(envelopeID => {
      if (typeof envelopeID !== "string") {
        setShowForm(false);
        setError(localization.FailCreateEnvelope);
        setLoading(false);
        return;
      }
      getDocumentURL(envelopeID, signers[0], tempRolesState.accountId).then(
        docuSignURL => {
          setEnvelopeCreated(true);
          boundStoreURL(docuSignURL);
          boundInsertCustomer(
            tempEnvelopeState,
            tempPageLoadState,
            tempRolesState,
            firstName,
            lastName,
            envelopeID,
            signers.find(item => {
              return item.accessCode;
            })
          );
        }
      );
    })
    .catch(() => {
      setShowForm(false);
      setLoading(false);
    });
}
