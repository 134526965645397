/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { LANG, MuxProvider, THEMES } from "@manulife/mux";
import Routes from "./components/Routes";
import store from "./store";

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

function App() {
  const language = useSelector(state => state.pageLoad.language);
  // const language = "en"; // Uncomment this line and comment the above ^ line to test desired language in local
  const [localization, setLocalization] = useState(LANG.EN_CA);
  useEffect(() => {
    if (language.length > 0) {
      const langVal = language === "fr" ? LANG.FR_CA : LANG.EN_CA;
      setLocalization(langVal);
    } else {
      setLocalization(LANG.EN_CA);
    }
  }, [language]);
  console.log(
    `AP language: ${language} converted to MUXLangProvider: ${localization}`
  );

  return (
    <MuxProvider
      themeProvider={{ theme: THEMES.canadianTheme }}
      langProvider={{ lang: localization }}
    >
      <Routes />
    </MuxProvider>
  );
}

export default AppWrapper;
