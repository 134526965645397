/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TextInput,
  DataTableExperimental,
  Button,
  Modal,
  H1,
  P,
  useLang,
  ButtonSelector
} from "@manulife/mux";
import { Link } from "react-router-dom";
import { getForms, getDocImage } from "../methods/eSignatureMethods";
import { getRoleData, search } from "../methods/formSelectMethods";
import {
  responed,
  loadInfo,
  dismissWarning,
  storeLanguage
} from "../actions/formSelectActions";
import warningImg from "../img/warning.png";
import prefillImage from "../img/checkmark.png";
import "../App.css";
import localizationObject from "../localization";

function FormSelect() {
  const redirectURL = window.REACT_APP_CIAMBFF_URL;
  const dispatch = useDispatch();
  const boundResponed = (message, disabled, passed) =>
    dispatch(responed(message, disabled, passed));
  const boundStoreLanguage = strings => dispatch(storeLanguage(strings));
  const boundLoadInfo = routeState => dispatch(loadInfo(routeState));
  const boundDismissWarning = flag => dispatch(dismissWarning(flag));
  const formInfo = useSelector(state => state.pageLoad.routeState);
  const [formNumberAndName, setformNumberAndName] = useState(
    formInfo.formnumber
  );
  const [forms, setForms] = useState([]);
  const [table, setTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const timeoutWarning = useSelector(state => state.pageLoad.dismissWarning);
  const isCIAM = useSelector(state => state.pageLoad.ciamSession);
  const [prevImgTable, setPrevImgTable] = useState([]);
  const [previewButtonState, setPreviewButtonState] = useState(false);
  const [previewText, setPreviewText] = useState("Preview eForm");
  const [Language, setLanguage] = useState({});
  const [filterLanguage, setfilterLanguage] = useState("EN");
  const lang = useLang();

  useEffect(() => {
    const MUXLang = lang.length > 0 ? lang : "en-CA";
    setLanguage(localizationObject[MUXLang]);
    const langVal = MUXLang === "en-CA" ? "EN" : "FR";
    setfilterLanguage(langVal);
  }, [lang]);

  function PreviewIMG(val) {
    const tableData = [];
    console.log(`is typeof ${typeof val} isArray?: ${Array.isArray(val)} isString?: ${typeof val === "string"} length: ${val.length}`);
    const lt = /</g; 
    const gt = />/g; 
    const ap = /'/g; 
    if(val.length !== 0){
      if(Array.isArray(val)) {
        for (const data of val) {
            if(typeof data === "string"){
              // sanitize input for DOMXSS protection snyk code scan
              const sanitizedVal = data.toString().replace(lt, "&lt;").replace(gt, "&gt;").replace(ap, "&#39;");
              console.log(`data is typeoff ${typeof data}`);
              tableData.push(
                <img
                  src={`data:image/png;base64, ${sanitizedVal}`}
                  alt={Language.PreviewForm}
                />)
            }
            else{
              tableData.push("No Preview Available");
            }
          }
      }
      else if(typeof val === "string"){
        // sanitize input for DOMXSS protection snyk code scan
        const sanitizedVal = val.toString().replace(lt, "&lt;").replace(gt, "&gt;").replace(ap, "&#39;");

        console.log(`val is typeoff ${typeof sanitizedVal}`);
        tableData.push(
          <img
            src={`data:image/png;base64, ${sanitizedVal}`}
            alt={Language.PreviewForm}
          />)
      }
      else
      {
        // sonarqube needs an else statement
      }
    }
    else{
      tableData.push("No Preview Available");
    }

    const refinedtableData =[];
    refinedtableData.push({PreviewImage: [...tableData]});
    setPrevImgTable(refinedtableData);
    setOpenModal(true);
  }

  useEffect(() => {
    const langVal = filterLanguage.length > 0 ? filterLanguage : "EN";
    search(formNumberAndName, forms, setformNumberAndName, setTable, langVal);
  }, [filterLanguage]);

  function toggleFilter(value) {
    const langVal = value === "FRform" ? "FR" : "EN";
    setfilterLanguage(langVal);
  }

  useEffect(() => {
    async function loadPageHandler() {
      let token = "";

      if (formInfo === undefined) {
        window.location.href = redirectURL;
      }
      try {
        token = formInfo.token;
      } catch (error) {
        boundResponed(Language.FieldError, false, false);
      }

      getForms()
        .then(res => {
          setPreviewText(
            Language.PreviewForm ? Language.PreviewForm : "Preview eForm"
          );
          const data = res.map((item, index) => {
            return {
              FormNumberAndName: item.RepsourceFormNumberAndName,
              Language: item.RepsourceLanguage,
              Select: (
                <Link to="/Signers">
                  <Button
                    id="SelectFormButton"
                    ariaLabel={`${Language.SRSelectForm1} ${item.RepsourceFormNumberAndName} ${Language.SRSelectForm2}`}
                    onClick={() => {
                      document.getElementById("root").focus();
                      boundLoadInfo({
                        ...formInfo,
                        formnumber: item.RepsourceFormNumber,
                        formLanguage: item.RepsourceLanguage
                      });
                    }}
                    customStyle={{
                      buttonStyle: {
                        background: "rgb(236, 100, 83)",
                        minWidth: "20px",
                        padding: "2px"
                      }
                    }}
                  >
                    {item.RepsourceFormNumber}
                  </Button>
                </Link>
              ),
              Prefill: item.Prefill ? (
                <img
                  aria-label="Form is pre-fillable"
                  src={prefillImage}
                  alt={Language.PrefillForm}
                  width="30px"
                  height="30px"
                  style={{ marginLeft: "18%" }}
                />
              ) : (
                ""
              ),
              Preview: (
                <div>
                  <Button
                    key={index}
                    id="PreviewButton"
                    ariaLabel={`${Language.SRPreviewForm} ${item.RepsourceFormNumberAndName}`}
                    disabled={previewButtonState}
                    onClick={() => {
                      setPreviewButtonState(true);
                      getRoleData(
                        item.RepsourceFormNumber,
                        item.RepsourceLanguage
                      ).then(result => {
                        getDocImage(result.accountId, result.templateId).then(
                          val => {
                            PreviewIMG(val);
                          }
                        );
                      });
                    }}
                    customStyle={{
                      buttonStyle: {
                        background: "rgb(236, 100, 83)",
                        minWidth: "20px",
                        padding: "2px"
                      }
                    }}
                  >
                    {previewButtonState === false
                      ? previewText
                      : Language.PleaseWait}
                  </Button>
                </div>
              )
            };
          });
          const refineddata = [];
          data.filter(item => {
            if (
              item.FormNumberAndName.toUpperCase().includes(formNumberAndName.toUpperCase()) && 
              item.Language.includes(filterLanguage)) {
                refineddata.push(item);
              }
            });
          setForms(data);
          setTable(refineddata);
        })
        .catch(err => {});
    }
    loadPageHandler();
  }, [previewButtonState, previewText, Language]);

  const tableHeader = useMemo(
    () => [
      {
        header: Language.FormName,
        accessorKey: "FormNumberAndName"
      },
      {
        header: Language.Select,
        accessorKey: "Select"
      },
      {
        header: Language.PrefillForm,
        accessorKey: "Prefill"
      },

      {
        header: Language.PreviewForm,
        accessorKey: "Preview"
      }
    ],
    [previewText, Language]
  );

  return Language.FormSelect ? (
    <div className="page">
      <Modal
        isOpen={openModal}
        id="PreviewModal"
        ariaDescribedBy="content"
        ariaLabelledBy={Language.PreviewForm}
        closeButtonAriaLabel="PreviewModalCloseBtn"
        customStyle={{
          modalStyle: {
            textAlign: "center",
            width: "60%",
            height: "70%"
          }
        }}
        onClose={() => {
          setPreviewButtonState(false);
          setOpenModal(false);
        }}
      >
        <P id="content">{Language.PreviewText}</P>
        <div>
          <DataTableExperimental
            columns={[
              {
                header: Language.PreviewForm,
                accessorKey: "PreviewImage"
              }
            ]}
            id="myTable"
            data={prevImgTable}
          />
        </div>
      </Modal>

      {!timeoutWarning ? (
        <div className="timeoutWarning">
          <img
            src={warningImg}
            alt="warning"
            width="60px"
            height="60px"
            style={{ float: "left" }}
          />
          <div className="timeoutButton">
            <Button
              ariaLabel={Language.SRCloseDialog}
              variant={Button.VARIANT.TERTIARY_NOICON}
              onClick={() => boundDismissWarning(true)}
            >
              X
            </Button>
          </div>
          <br />
          <P>{isCIAM ? Language.timingError : Language.TimeoutWarning}</P>
        </div>
      ) : null}
      <div className="padding">
        <H1>{Language.FormSelect}</H1>
        <P>{Language.FormInstructions}</P>
        <label className="m1" htmlFor="Form Number">
          {Language.FormNumber}
          <br />
          <TextInput
            id="FormNumberAndName"
            ariaLabel={Language.Search}
            placeholder={Language.Search}
            value={formNumberAndName}
            customStyle={{
              rootStyle: {
                maxWidth: "50%"
              }
            }}
            onChange={value =>
              search(value, forms, setformNumberAndName, setTable, filterLanguage)
            }
          />
          <br />
          <span className="padding">
            <ButtonSelector
              id="BtnSelectorFilter"
              value={filterLanguage === "FR" ? "FRform" : "ENform"}
              values={[
                {
                  label: Language.btnSelectorFilterFR,
                  value: 'FRform',
                },
                {
                  label: Language.btnSelectorFilterEN,
                  value: 'ENform',
                },
              ]}
              onChange={value => toggleFilter(value)}
            />
          </span>
        </label>
      </div>
      <div className="padding">
        <DataTableExperimental
          columns={tableHeader}
          id="myTable"
          data={table}
        />
      </div>
    </div>
  ) : null;
}

export default FormSelect;
