/* eslint-disable prettier/prettier */
const Ordinal = String.fromCharCode(186);
const localizationObject = {
  'en-CA': {
    btnSelectorFilterFR: 'French forms',
    btnSelectorFilterEN: 'English forms',
    TableNoResult: 'No results found',
    DateFormatISO: '(YYYY-MM-DD)',
    DateTracking: 'You can only view records from the last 30 days',
    PolicyNumberTooltip:'Enter up to 15 uppercase letters and/or numbers',
    SessionTimeout: 'Session Timeout',
    SessionTimeoutMessage: 'Your session is about to expire, incomplete eforms will be lost.',
    Reset: 'Reset',
    SignOut: 'Sign Out',
    SignOutMessage: 'You have successfully signed out',
    SendEnvelopeTitle: 'eSignature - eForm Submission',
    DescriptionL1:
      'We will send eSignature emails to the signing parties below.',
    DescriptionL2S1: 'You can consult ',
    MyClient: 'My Clients',
    DescriptionL2S2: 'to confirm the signing parties.',
    DescriptionL3:
      'To be eligible for eSignature, the transaction should not require the signature of a collateral assignee/hypothecary creditor.',
    PolicyNumber: 'Policy / account number(s)',
    EmailAccessCode: 'Email Access code',
    LastName: 'Last name',
    FirstName: 'First name',
    SigningParty: 'Signing party',
    EmailAddress: 'Email address',
    PwordLabel: 'Password',
    Send: 'SEND',
    Discard: 'Reset',
    EnvelopeStatusTitle: 'eForms - Tracking Form Requests',
    DateRange: 'From date',
    DateSent: 'Date sent',
    Search: 'Search',
    Owner: 'Owner #1',
    GetAdditionalInfo: 'If you need additional information',
    FormNumber: 'eForm number or name',
    Click: '+ Click here',
    Role: 'Role',
    Status: 'Status',
    FooterDesc1:
      'If you have questions or concerns, please contact dedicated your Advisor Contact Centre.',
    FooterDesc2: 'Independent Advisors: 1-877-626-8543',
    FooterDesc3: 'MGA Head Offices: 1-888-304-3188',
    FooterDesc4: 'MGA Advisors: Contact your MGA Head Office',
    FooterDesc5: 'National Accounts: 1-888-533-9444',
    FullNamePH: 'Enter full legal name as per our records',
    EmailPH: 'Each signing party must have their own email address',
    SuccessMessage:
      'The eForm has successfully been sent to all signing parties. The password is',
    Optional: 'Optional',
    Mandatory: 'Mandatory',
    DateFormat: 'format: yyyy-mm-dd',
    DateError: 'Date does not match ',
    FormLanguage: 'Form language',
    English: 'English',
    French: 'French',
    FieldError: 'Not all mandatory fields have been completed',
    URL:
      'https://repsource.manulife.com/ds001ap/wps/myportal/Repsource/PrivateHome/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zivQx9TTwc3Q18DNx8DAwcfRyDTN1dnA0sfEz1wwkpiAJKG-AAjgb6BbmhigDCBRYE/dz/d5/L2dBISEvZ0FBIS9nQSEh/',
    ServerError:
      'We are experiencing a temporary technical issue - please try again later',
    PreFill: 'Advisor Optional Prefill',
    SignaturePending: 'Signature Pending',
    PolicySearch: 'Search',
    ClientViewed: 'Client has viewed eForm; Signature Pending',
    SignatureReceived: 'Signature Received',
    ClientDeclined: 'Client Declined to Sign',
    Expired: 'Form has expired',
    InvalidEmail: 'Invalid Email Address for Client',
    ClientFailed: 'Client Failed Authentication (incorrect password)',
    FirstFieldError:
      'Please enter the full name and email address for the first signing party',
    EmailBased: 'Email-Based',
    SignerLocation: 'Signer Location',
    NextResponse: 'there are no more in-person signers',
    Home: 'Home',
    EmailPlaceholder: 'Email address',
    InPerson: 'In person',
    Email: 'By email',
    NoNameError:
      'Please enter a full name and email for at least the first signing party',
    NoEmailError: 'Please enter an email for each signer',
    InvalidEmailError: 'Please enter valid email addresses',
    NoNameEmailError: 'Please enter a full name for each email',
    Signers: 'List everyone who needs to sign',
    EmailLabel: 'Email Address',
    SigningMethodLabel: 'Signing Method',
    RoleLabel: 'Role',
    Next: 'Next',
    RetrieveEnglish: 'Retrieve English',
    RetrieveFrench: 'Retrieve French',
    Select: 'Select',
    PolicyNumberError: 'Please enter a policy / account number(s)',
    InvalidAccessCodeError: 'Please enter a valid email access code',
    EmailAccessCodeTooltip:
      'The email access code can be changed by entering a new code in this field. Code must have at least 6 numeric digits and maximum of 10.',
    Confirmation: 'Launch the eForm',
    AccessCodeInstructions1:
      'NOTE: If you need to lookup the code in the future, please access the ‘Tracking eForm Requests’ screen from the home page under ‘Quick Links’.',
    AccessCodeInstructions2:
      'If you chose to receive a copy, you’ll receive an email with the completed, signed eForm.',
    AccessCode: 'Access code ',
    PreviewInstructions: 'The eForm is ready for you to preview',
    LaunchAdvisorView: 'Launch advisor view',
    InvalidNextError:
      "Something went wrong. It's possible that the previous signer did not finish. Please close eForms and start over",
    Done: 'Done',
    CreateEnvelope: 'Start',
    PolcyNumberNotice: 'Please enter a policy number',
    Loading: 'Loading',
    FormSelect: 'Choose an eForm',
    SigningScenario: 'Choose who signs',
    ScenarioInstructions:
      'This eForm can be signed by you or your client(s), who would you like to have sign the eForm?',
    AdvisorSigning: 'Just me',
    ClientSigning: 'Just my client(s)',
    Advisor: 'Advisor',
    IncorrectNaming: 'Please enter a first name and a last name',
    EnvelopeSigners: 'Envelope Signers',
    CarbonCopy: 'List everyone who needs to receive a copy after signing.',
    UniqueEmailError: 'All signer emails need to be different',
    PreviewForm: 'Preview eForm',
    PleaseWait: 'Please Wait',
    PreviewText:
      'Just an image of the eForm, you cannot enter any information on the eForm – for reference only.',
    Finished: 'You’re done signing. Thank you!',
    RequestSent: 'Request Sent',
    TimeoutWarning:
      'You have 60 minutes to complete this eForm or the session will expire',
    FormInstructions: 'Which eForm would you like to send?',
    FormName: 'Form name',
    SigningInstructions1:
      'All signing party(s) full name and valid email address must be entered below. (Confirm roles and signing parties using available tools such as ',
    SigningInstructions1a: ', Dataphile, etc.)',
    SigningInstructions2:
      'For signing parties who are face to face with the advisor and want to sign now, select “In Person” as the signing method. ',
    SigningInstructions3:
      'For signing parties who are not available to meet face to face with the advisor, select “By Email” as the signing method.',
    SigningInstructions4:
      'each signer(s) must have their own email address – duplicate emails cannot be used.',
    SigningReminder: 'Reminder, ',
    InPersonInstructions1: 'The eForm is ready for you to review.',
    InPersonInstructions2:
      'Please begin by reading the Terms and conditions and click agree. After that, click ‘Continue’ and you have the option of entering any remaining applicable fields. When you’re done, click ‘Finish’.',
    InPersonInstructions3:
      'Once you’re done, click the ‘Next’ button, and turn your screen towards your Client - they’ll be able to complete the remainder of the eForm and sign it.',
    InPersonSignerInstructions:
      'Please begin by reading the Terms and conditions and click agree. After that, click ‘Continue’ and complete the eForm. When you’re done, click ‘Finish’.',
    EmailInstructions:
      "Once you click ‘Finish’, the eForm is emailed to your Client(s) to sign. Don't forget to provide their access code above.",
    BothInstructions:
      "Now that all in-person signer(s) are done, the eForm will be emailed to your email-based signer(s). Don't forget to provide them with the access code above.",
    NextSignerInstructions1: 'If you have not clicked ',
    NextSignerInstructions2: 'and ',
    NextSignerInstructions3:
      'please do so in order to proceed to the next step.',
    GoBack: 'Go back',
    IAgree: '‘I agree’ ',
    Finish: '‘Finish’ ',
    NextStep: 'Next step',
    LaunchClientView: 'Launch client view',
    NextError:
      'Before clicking this button you need to review and complete the form (if applicable) and then click ‘Finish’',
    PrefillForm: 'Advisor prefill option',
    NoRowsText: 'No additional recipients required',
    SRCloseDialog: 'Click ‘X’ to close timeout warning box',
    SRSelectForm1: 'Select form',
    SRSelectForm2: 'to be signed',
    SRPreviewForm: 'Preview form',
    SRToggleText: 'Sign by email or Sign In-person',
    FailCreateEnvelope:
      'An error has occurred while creating envelope, please contact DTSC at 1-800-667-4266 or by email DTSC@manulife.com',
    IEIncompatibilityNotice:
      'This part of our site is temporarily unavailable using Internet Explorer 11.  We are working to resolve the issue and in the interim, you will need to use a different browser such as Chrome, Safari, or Microsoft Edge.',
    timingError: 'After 30 minutes of inactivity the session will expire, incomplete eforms will be lost.',
    CIAMRedirectError:
      'An error has occurred during authentication, please contact DTSC at 1-800-667-4266 or by email DTSC@manulife.com and provide the following error code: '
  },
  'fr-CA': {
    btnSelectorFilterFR: 'Français formulaires',
    btnSelectorFilterEN: 'Anglais formulaires',
    TableNoResult: 'La recherche n’a donné aucun résultat',
    DateFormatISO: '(AAAA-MM-JJ)',
    DateTracking: 'Vous pouvez voir les entrées des 30 derniers jours seulement',
    PolicyNumberTooltip:'Saisir jusqu’à 15 lettres majuscules ou chiffres',
    SessionTimeout: 'Session expirée',
    SessionTimeoutMessage: 'Votre session va bientôt expirer, les formulaires électroniques incomplets seront perdus.',
    Reset: 'Réinitialiser',
    SignOut: 'Se déconnecter',
    SignOutMessage: 'Vous avez bien fermé votre session.',
    SendEnvelopeTitle: 'Signature électronique – Envoi d’un formulaire',
    DescriptionL1:
      'Nous enverrons des courriels demandant une signature électronique aux signataires indiqués ci-après.',
    DescriptionL2S1: 'Vous pouvez consulter',
    MyClient: 'Mes clients',
    DescriptionL2S2: 'pour confirmer les signataires.',
    DescriptionL3:
      'Pour être admissible à la signature électronique, l’opération ne doit pas exiger la signature du cessionnaire en garantie ou du créancier hypothécaire.',
    PolicyNumber: 'Numéro(s) de contrat ou de compte',
    EmailAccessCode: "code d'accès e-mail",
    SigningParty: 'Signataire',
    EmailAddress: 'Adresse de courriel',
    PwordLabel: 'Mot de passe',
    Send: 'ENVOYER',
    Discard: 'Réinitialiser',
    EnvelopeStatusTitle:
      'Signature électronique – Suivi des demandes de formulaire',
    DateRange: 'À partir du',
    DateSent: 'Date d’envoi',
    Search: 'Rechercher',
    Owner: `Nom du titulaire n${Ordinal} 1`,
    GetAdditionalInfo: 'Si vous avez besoin de plus d’information',
    Click: '+ Cliquez ici',
    Role: 'Fonction',
    Status: 'État',
    FooterDesc1:
      'Si vous avez des questions ou des préoccupations, veuillez communiquer avec votre Centre d’information des conseillers.',
    FooterDesc2: 'Conseillers indépendants : 1 877 626-8543',
    FooterDesc3: 'Sièges sociaux des AGP : 1 888 304-3188',
    FooterDesc4:
      'Conseillers des AGP : Communiquez avec le siège social de votre AGP',
    FooterDesc5: 'Réseau des comptes nationaux : 1 888 533-9444',
    FullNamePH: 'Entrez le nom légal complet conformément à nos dossiers',
    EmailPH: 'Chaque signataire doit avoir sa propre adresse de courriel',
    SuccessMessage:
      'Le formulaire a été envoyé à tous les signataires. Le mot de passe est ',
    Optional: 'Champ facultatif',
    Mandatory: 'Champ obligatoire',
    DateFormat: 'format suivant:  (AAAA-MM-JJ)',
    DateError: 'Le format de la date doit correspondre au ',
    FormLanguage: 'Langue du formulaire',
    English: 'Anglais',
    French: 'Français',
    FieldError: 'Tous les champs obligatoires n’ont pas été remplis',
    URL:
      'https://repsource.manulife.com/ds001ap/wps/myportal/Repsource/PrivateHome/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zivQx9TTwc3Q18DNx8DAwcfRyDTN1dnA0sfEz1wwkpiAJKG-AAjgb6BbmhigDCBRYE/dz/d5/L0lDUmlTUSEhL3dHa0FKRnNBLzROV3FpQSEhL2Zy/',
    ServerError:
      'Il y a actuellement un problème technique. Veuillez réessayer plus tard',
    PreFill: 'Pré-remplissage Optionnel Complet',
    SignaturePending: 'Signature en attente',
    PolicySearch: 'Recherche',
    ClientViewed: 'Formulaire consulté par le client; signature en attente',
    SignatureReceived: 'Signature reçue',
    ClientDeclined: 'Refus de signer du client',
    Expired: 'Expiration du temps prévu pour le formulaire',
    InvalidEmail: 'Adresse de courriel du client non valide',
    ClientFailed:
      'Échec de l’authentification du client (mot de passe incorrect)',
    FirstFieldError:
      "Veuillez saisir le nom complet et l'adresse courriel du premier signataire",
    InPerson: 'En personne',
    EmailBased: 'Par courrier électronique',
    SignerLocation: 'Emplacement du signataire',
    Next: 'suivant',
    NextResponse: "il n'y a plus de signataires en personne",
    Home: 'FHome',
    EmailPlaceholder: 'Adresse de courriel',
    Email: 'Par courriel',
    NoNameError:
      'Veuillez entrer un nom et un courriel pour au moins le premier signataire',
    NoEmailError: 'Veuillez entrer un courriel pour chaque signataire',
    InvalidEmailError: "S'il vous plaît entrer des adresses courriel valides",
    NoNameEmailError: "S'il vous plaît entrer un nom pour chaque courriel",
    Signers: 'Nommez toutes les personnes qui doivent signer',
    EmailLabel: 'Adresse de courriel',
    SigningMethodLabel: 'Méthode de signature',
    RoleLabel: 'Rôle',
    RetrieveEnglish: 'Fetrieve English',
    RetrieveFrench: 'FRetrieve French',
    Select: 'Sélectionner',
    PolicyNumberError: 'Veuillez entrer un numéro(s) de contrat ou de compte',
    InvalidAccessCodeError: "Entrez un code d'accès de courriel valide",
    EmailAccessCodeTooltip:
      "Le code d'accès e-mail peut être modifié en entrant un nouveau code dans ce champ. Le code doit comporter au moins 6 chiffres et un maximum de 10.",
    Confirmation: 'Lancer le formulaire',
    AccessCodeInstructions1:
      'REMARQUE : Pour consulter le code à nouveau en cas de besoin, accédez à l’écran « Suivi des formulaires » à partir de la page d’accueil d’Inforep, sous « Liens utiles ».',
    AccessCodeInstructions2:
      'Si vous avez choisi de recevoir une copie, vous recevrez un courriel contenant le formulaire rempli et signé.',
    AccessCode: 'Code d’accès : ',
    PreviewInstructions: 'The form is ready for you to preview',
    LaunchAdvisorView: 'Lancer Vue Conseiller',
    InvalidNextError:
      "Quelque chose s'est mal passé. Il est possible que le signataire précédent n’ait pas fini. S'il vous plaît fermez Sierra et recommencez",
    Done: 'Terminé',
    CreateEnvelope: 'Commencer',
    PolcyNumberNotice: 'FPlease enter a policy number',
    Loading: 'FLoading',
    FormSelect: 'Choisir un formulaire',
    SigningScenario: 'Choisir les personnes qui doivent signer le formulaire',
    ScenarioInstructions:
      'Le formulaire peut être signé par vous ou vos clients. Qui signera le formulaire?',
    AdvisorSigning: 'Seulement moi',
    ClientSigning: 'Seulement mes clients',
    LastName: 'Nom de famille',
    FirstName: 'Prénom',
    Advisor: 'Conseiller',
    FormNumber: 'Numéro ou nom du formulaire',
    IncorrectNaming: "S'il vous plaît entrer un prénom et un nom",
    EnvelopeSigners: 'FEnvelope Signers',
    CarbonCopy:
      'Nommez toutes les personnes qui doivent recevoir une copie signée.',
    UniqueEmailError:
      'Tous les courriels de signataire doivent être différents',
    PreviewForm: 'Aperçu du Formulaire',
    PleaseWait: "S'il vous plaît, attendez",
    PreviewText:
      'Juste une image du formulaire, vous ne pouvez entrer aucune information sur le formulaire - pour référence seulement.',
    RequestSent: 'Demande envoyée',
    Finished: 'Vous avez fini de signer, merci!',
    TimeoutWarning:
      'Vous avez 60 minutes pour remplir ce formulaire sinon la session expirera.',
    FormInstructions: 'Quel formulaire voulez-vous envoyer?',
    FormName: 'Nom du formulaire',
    SigningInstructions1:
      'Tous les signataires autorisés doivent indiquer leur nom complet ainsi qu’une adresse de courriel valide ci-dessous. (Confirmez les rôles et les signataires au moyen d’outils comme ',
    SigningInstructions1a: ', Dataphile, etc.)',
    SigningInstructions2:
      'Si les signataires sont avec le conseiller et veulent signer maintenant, sélectionnez la méthode de signature « En personne ».',
    SigningInstructions3:
      'Si les signataires ne sont pas en mesure de rencontrer le conseiller en personne, sélectionnez la méthode de signature « Par courriel ».',
    SigningInstructions4:
      'que chacun des signataires doit avoir sa propre adresse de courriel – la même adresse de courriel ne peut être saisie plus d’une fois.',
    SigningReminder: 'N’oubliez pas ',
    InPersonInstructions1: 'Vous pouvez maintenant consulter le formulaire.',
    InPersonInstructions2:
      'Veuillez commencer par lire les termes et conditions et cliquer sur accepter. Après cela, cliquez sur «Continue» et vous aurez la possibilité de saisir tous les champs applicables restants. Lorsque vous avez terminé, cliquez sur «Terminer»',
    InPersonInstructions3:
      'Lorsque vous avez terminé, cliquez sur le bouton « suivant » et montrez l’écran à votre client; il pourra terminer de remplir le formulaire et le signer.',
    InPersonSignerInstructions:
      'Veuillez d’abord lire les modalités, puis cliquer sur le bouton de consentement. Cliquez ensuite sur « Continue » et remplissez le formulaire. Lorsque vous avez terminé, cliquez sur « Finish ».',
    EmailInstructions:
      "Ce faisant, le formulaire sera envoyé à vos clients par courriel pour la signature. N'oubliez pas de fournir leur code d'accès ci-dessus.",
    BothInstructions:
      "Maintenant que tous les signataires présents ont terminé, le formulaire sera envoyé par courriel aux signataires qui ne sont pas présents. N'oubliez pas de leur fournir le code d'accès ci-dessus.",
    NextSignerInstructions1: 'Si vous n’avez pas cliqué sur ',
    NextSignerInstructions2: 'et ',
    NextSignerInstructions3:
      'veuillez le faire afin de passer à l’étape suivante.',
    GoBack: 'Retourner',
    IAgree: '« J’accepte » ',
    Finish: '« Terminer », ',
    NextStep: 'Étape suivante',
    LaunchClientView: 'Montrer au client',
    NextError:
      'Avant de cliquer sur ce bouton, vous devez vérifier et remplir le formulaire (s\'il y a lieu), puis cliquer sur " Terminer ".',
    PrefillForm: 'Option de préremplissage conseiller',
    NoRowsText: 'Aucun destinataire additionnel est requis',
    SRCloseDialog:
      "Cliquez sur «X» pour fermer la boîte d'avertissement de délai d'attente",
    SRSelectForm1: 'Sélectionnez le formulaire',
    SRSelectForm2: 'à signer',
    SRPreviewForm: 'Aperçu du formulaire',
    SRToggleText: 'Signer par couriel. Signer en personne.',
    FailCreateEnvelope:
      'Une erreur s’est produite lors de la création de l’enveloppe, , veuillez contacter Centre de soutien technique, Distribution au 1-800-667-4266… ou envoyer un courriel à dtsc@manuvie.ca',
    IEIncompatibilityNotice:
      'Cette partie de notre site est temporairement non disponible avec Internet Explorer 11. Nous travaillons à résoudre le problème et entre-temps, vous devrez utiliser un autre navigateur tel que Chrome, Safari ou Microsoft Edge.',
    timingError: 'Après 30 minutes d’inactivité, la session expirera et les formulaires électroniques incomplets seront perdus.',
    CIAMRedirectError:
      'Une erreur s’est produite lors de l’authentification, , veuillez contacter le Centre de soutien technique - Distribution au 1-800-667-4266… ou envoyer un courriel à dtsc@manuvie.ca en fournissant le code d’erreur ci-dessous : '
  }
};

export default localizationObject;
