/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-curly-newline */
import React from "react";
import { TextInput } from "@manulife/mux";
import Switch from "react-switch";
import * as EmailValidator from "email-validator";

const onToggle = (
  index,
  signerToggles,
  toggleStates,
  setSignerToggles,
  setToggleStates
) => {
  const selection = parseInt(index, 10);
  const toggleSelection = signerToggles.slice(selection);
  const toggleOmission = signerToggles.slice(0, selection);
  const disableSelection = toggleStates.slice(selection + 1);
  const disableOmission = toggleStates.slice(0, selection + 1);

  const toggleState = toggleSelection.find(() => true);

  setSignerToggles(
    [...toggleOmission].concat(
      toggleSelection.map(() => {
        return !toggleState;
      })
    )
  );

  setToggleStates(
    [...disableOmission]
      .map(() => false)
      .concat(disableSelection.map(() => !toggleState))
  );
};
export const populateSignerRows = (
  tempRoles,
  partyFirstNames,
  partyLastNames,
  emails,
  setPartyFirstNames,
  setPartyLastNames,
  setEmails,
  signerToggles,
  toggleStates,
  setSignerToggles,
  setToggleStates,
  localization
) => {
  const tempFirstNames = [...partyFirstNames];
  const tempLastNames = [...partyLastNames];
  const tempEmails = [...emails];
  const tempRows = [];

  const setTableValues = (val, i, row) => {
    if (row === "partyFirstName") {
      tempFirstNames[i] = val;
      setPartyFirstNames([...tempFirstNames]);
    } else if (row === "partyLastName") {
      tempLastNames[i] = val;
      setPartyLastNames([...tempLastNames]);
    } else {
      tempEmails[i] = val;
      setEmails([...tempEmails]);
    }
  };
  // Signer Table
  for (let i = 0; i < tempRoles.length; i += 1) {
    tempRows.push({
      role: tempRoles[i].roleName,
      partyFirstName: (
        <TextInput
          customStyle={{
            rootStyle: {
              maxWidth: "70%"
            }
          }}
          placeholder={localization.FirstName}
          onChange={val => {
            setTableValues(val, i, "partyFirstName");
          }}
          value={tempFirstNames[i]}
          id="PartyFname"
        />
      ),
      partyLastName: (
        <TextInput
          customStyle={{
            rootStyle: {
              maxWidth: "70%"
            }
          }}
          placeholder={localization.LastName}
          onChange={val => {
            setTableValues(val, i, "partyLastName");
          }}
          value={tempLastNames[i]}
          id="PartyLname"
        />
      ),
      email: (
        <TextInput
          customStyle={{
            rootStyle: {
              maxWidth: "70%"
            }
          }}
          placeholder={localization.EmailPlaceholder}
          onChange={val => {
            setTableValues(val, i, "email");
          }}
          value={tempEmails[i]}
          id="PartyEmail"
        />
      ),
      signMethod: (
        <label htmlFor="signingMethod">
          <span>{localization.InPerson}</span>
          <Switch
            name={tempRoles[i].roleName}
            disabled={toggleStates[i]}
            checked={signerToggles[i]}
            onChange={() =>
              onToggle(
                i,
                signerToggles,
                toggleStates,
                setSignerToggles,
                setToggleStates
              )
            }
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#888"
            aria-label={localization.SRToggleText}
          />
          <span>{localization.Email}</span>
        </label>
      )
    });
  }
  return tempRows;
};

export const populateCopyRows = (
  carbonCopies,
  copyFirstNames,
  copyLastNames,
  copyEmails,
  setCopyFirstNames,
  setCopyLastNames,
  setCopyEmails,
  localization
) => {
  const tempFirstNames = [...copyFirstNames];
  const tempLastNames = [...copyLastNames];
  const tempEmails = [...copyEmails];
  const tempCopyRows = [];
  // Carbon Copy Table
  for (let i = 0; i < carbonCopies.length; i += 1) {
    tempCopyRows.push({
      role: carbonCopies[i].roleName,
      copyFirstName: (
        <TextInput
          customStyle={{
            rootStyle: {
              maxWidth: "70%"
            }
          }}
          placeholder={localization.FirstName}
          onChange={e => {
            tempFirstNames[i] = e;
            setCopyFirstNames([...tempFirstNames]);
          }}
          value={tempFirstNames[i]}
        />
      ),
      copyLastName: (
        <TextInput
          customStyle={{
            rootStyle: {
              maxWidth: "70%"
            }
          }}
          placeholder={localization.LastName}
          onChange={e => {
            tempLastNames[i] = e;
            setCopyLastNames([...tempLastNames]);
          }}
          value={tempLastNames[i]}
        />
      ),
      copyEmail: (
        <TextInput
          customStyle={{
            rootStyle: {
              maxWidth: "70%"
            }
          }}
          placeholder={localization.EmailPlaceholder}
          onChange={e => {
            tempEmails[i] = e;
            setCopyEmails([...tempEmails]);
          }}
          value={tempEmails[i]}
        />
      )
    });
  }
  return tempCopyRows;
};

export const formatPrefill = async (
  advisorPrefill,
  tempPageLoadState,
  _localization,
  policyNumber,
  partyFirstNames,
  partyLastNames,
  signerToggles,
  emails,
  carbonCopies,
  copyFirstNames,
  copyEmails,
  copyLastNames,
  roles,
  accessCode
) => {
  const prefillSigners = [];
  const formNumber = tempPageLoadState.routeState.formnumber;
  const isAdvisorPrefill = formNumber[formNumber.length - 1] === "P";

  const tabs = {
    textTabs: [
      {
        tabLabel: "Policy_Number_1",
        value: policyNumber
      }
    ]
  };

  if (advisorPrefill.roleName) {
    prefillSigners.push({
      roleName: advisorPrefill.roleName,
      name: `${tempPageLoadState.repsourceFirstname} ${tempPageLoadState.repsourceLastname}`,
      clientUserId: "9999",
      email: tempPageLoadState.repsourceEmail,
      ...(isAdvisorPrefill && { tabs })
    });
  }

  roles.forEach((item, i) => {
    if (
      partyFirstNames[i] !== "" &&
      partyFirstNames[i] !== undefined &&
      item.roleName !== "Advisor" &&
      item.roleName !== "Conseiller"
    ) {
      tabs.textTabs.push({
        tabLabel: `Customer_First_Name_${i + 2}`,
        value: partyFirstNames[i].toUpperCase()
      });
      tabs.textTabs.push({
        tabLabel: `Customer_Last_Name_${i + 2}`,
        value: partyLastNames[i].toUpperCase()
      });
    }
  });
  roles.forEach((item, i) => {
    if (partyFirstNames[i] !== "" && partyFirstNames[i] !== undefined) {
      prefillSigners.push({
        ...(signerToggles[i] && { accessCode }),
        ...(!signerToggles[i] && { clientUserId: "9999" }),
        email: emails[i],
        name: `${partyFirstNames[i]} ${partyLastNames[i]}`,
        roleName: item.roleName,
        ...(!isAdvisorPrefill && i === 0 && { tabs })
      });
    }
  });
  carbonCopies.forEach((item, i) => {
    if (copyFirstNames[i] !== "" && copyFirstNames[i] !== undefined) {
      prefillSigners.push({
        email: copyEmails[i],
        name: `${copyFirstNames[i]} ${copyLastNames[i]}`,
        roleName: item.roleName
      });
    }
  });
  return prefillSigners;
};

export const validation = (
  e,
  accessCode,
  emails,
  partyFirstNames,
  partyLastNames,
  policyNumber,
  copyEmails,
  copyFirstNames,
  copyLastNames,
  setError,
  localization,
  advisorPrefill,
  tempPageLoadState,
  signerToggles,
  carbonCopies,
  roles,
  boundSubmitPrefill
) => {
  let validEmail = emails[0] !== "";
  let noEmail = false;
  let incorrectNaming = false;
  let emailNoName = false;
  let duplicateEmail = false;
  const noName = partyFirstNames[0] === "" || partyLastNames[0] === "";
  const noPolicyNumber = policyNumber.length <= 0;

  // check if access code meets the minimum number of characters (6)
  // and the maximum of 10
  const accessCodeVal = accessCode.trim();
  const validAccessCodeLength = 6;
  const maxLength = 10;
  let invalidAccessCode =
    accessCodeVal.length < validAccessCodeLength ||
    accessCodeVal.length > maxLength;

  // check if access code contains alphanumeric (number only)
  invalidAccessCode = Number.isNaN(Number(accessCodeVal))
    ? true
    : invalidAccessCode;

  const filteredArray = emails.filter(
    (val, index, arr) => arr.indexOf(val) === index && val !== ""
  );
  const originalArray = emails.filter(val => val !== "");
  if (filteredArray.length !== originalArray.length) {
    duplicateEmail = true;
  }

  emails.forEach((item, i) => {
    if (item !== "") {
      validEmail = EmailValidator.validate(item);
    }
    if (partyFirstNames[i] !== "" && partyLastNames[i] !== "" && item === "") {
      noEmail = true;
    }
    if (
      (partyFirstNames[i] !== "" || partyLastNames[i] !== "") &&
      item === ""
    ) {
      incorrectNaming = true;
    }
    if (
      item !== "" &&
      (partyFirstNames[i] === "" || partyLastNames[i] === "")
    ) {
      emailNoName = true;
    }
  });
  copyEmails.forEach((item, i) => {
    if (item !== "") {
      validEmail = EmailValidator.validate(item);
    }
    if (copyFirstNames[i] !== "" && copyLastNames[i] !== "" && item === "") {
      noEmail = true;
    }
    if ((copyFirstNames[i] !== "" || copyLastNames[i] !== "") && item === "") {
      incorrectNaming = true;
    }
    if (item !== "" && (copyFirstNames[i] === "" || copyLastNames[i] === "")) {
      emailNoName = true;
    }
  });

  if (noName) {
    e.preventDefault();
    setError(localization.NoNameError);
  } else if (invalidAccessCode) {
    e.preventDefault();
    setError(localization.InvalidAccessCodeError);
  } else if (!validEmail) {
    e.preventDefault();
    setError(localization.InvalidEmailError);
  } else if (noPolicyNumber) {
    e.preventDefault();
    setError(localization.PolicyNumberError);
  } else if (noEmail) {
    e.preventDefault();
    setError(localization.NoEmailError);
  } else if (emailNoName) {
    e.preventDefault();
    setError(localization.NoNameEmailError);
  } else if (incorrectNaming) {
    e.preventDefault();
    setError(localization.IncorrectNaming);
  } else if (duplicateEmail) {
    e.preventDefault();
    setError(localization.UniqueEmailError);
  } else {
    formatPrefill(
      advisorPrefill,
      tempPageLoadState,
      localization,
      policyNumber,
      partyFirstNames,
      partyLastNames,
      signerToggles,
      emails,
      carbonCopies,
      copyFirstNames,
      copyEmails,
      copyLastNames,
      roles,
      accessCode
    ).then(result => {
      boundSubmitPrefill(result, policyNumber);
    });
    setError("");
  }
};
